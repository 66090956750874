<template>
  <div>
    <div class="container">
        <v-form ref="form" lazy-validation>
          <form-config
            ref="config"
            :formulario="formulario"
            tabela="apontamentoproducao"
          />
            <div class="d-flex justify-center contaainer">
              <v-text-field ref="secador"
              outlined label="Secador" class="textfield n-center"
              background-color="white" type="number"
              :rules="rules.appsecador"
              v-model="formulario.appsecador"
              @keyup.enter="$refs.secador.blur(); $refs.lote.$children[0].focus();"></v-text-field>
              <!-- <p>Selecione um lote para criar um novo apontamento.</p> -->
              <card-auto-complete ref="lote"
              label="Lote P.A."
              class="textfield n-center"
              :dados="lotesdaop" 
              v-model="formulario.appidaberturalote"
              itemLabel="ablnrocontrole"  
              item-value="ablidaberturalote"
              :return-object="false"
              hide-details
              icon="mdi-qrcode-scan"
              :action="$_onClickQR"
              @keyup.enter="$refs.lote.$children[0].blur(); $refs.umidade.focus()"
            />
                <div class="space">
                </div>
              <!-- <v-text-field outlined label="Data/Hora" class="textfield n-center" readonly
              v-model="formulario.appdatapesagem"></v-text-field> -->
              <v-text-field ref="umidade"
              outlined label="% Umidade" class="textfield n-center" type="number"
              background-color="white" :rules="rules.appumidade"
              v-model="formulario.appumidade"
              @keyup.enter="$refs.umidade.blur(); $_submit"></v-text-field>
              <v-btn color="primary" class="salvar" @click="$_submit">Salvar</v-btn>
            </div>
              <!-- <v-btn @click="$_cancelar">Cancelar</v-btn> -->
        </v-form>
      <!-- <v-text-field></v-text-field> -->
      <br/>
    </div>
  </div>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import { ROUTE_PRODUCAO_CADASTRO, ROUTE_LOTES_FINALIZADOS, ROUTE_QR  } from '@/constants/ROUTES';

import FormConfig from '@/components/form/components/form-config/Index.vue';
import CardAutoComplete from '@/components/core/card-auto-complete/Index.vue'

import { OPLOCAL_STORAGE } from '@/constants/STORAGE'


// import { OPLOCAL_STORAGE, CADPRODUCAO_STORAGE } from '@/constants/STORAGE';

import db from '@/plugins/dexie';

import moment from 'moment-timezone';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}


// import AppCardAtividadeSkeleton from '../../components/app/card/atividade/Skeleton.vue';
import { SetupWebSocket } from '../../utils/ws/websocket';
import errorHandler from '@/utils/error-handler';

export default {
  name: 'ViewLoteC',

  components: {
    FormConfig,
    CardAutoComplete,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_PRODUCAO_CADASTRO,
      ],

      fabrica: null,
      loading: true,
      lotes: null,
      lotesdaop: null,
      lotesfechados: null,
      proximolote: null,
      proximoembalagem: null,
      idordemproducao: null,

      formulario: {
        appsecador: null,
        appdatapesagem: null,
        appumidade: null,
        appidaberturalote: null,
      },

      rules: {
        appsecador: [ v => !!v || 'Secador é obrigatório' ],
        appumidade: [ v => !!v || 'Umidade é obrigatório' ],
      },

      // ESSA VIEW ESTÁ COM VARIAS FUNÇÕES A MAIS, RETIRAR DEPOIS
      

      itensComEsseNro: null,

      lotesMaisRecentes: [],

      abrirdialog: false,

      uaaa: false,

      w_peso: null,
    };
  },

  methods: {

    async $_setup() {
      this.formulario.appdatapesagem = new Date();
      this.formulario.appdatapesagem.setHours(this.formulario.appdatapesagem.getHours() - 3);
      this.lotesdaop = await db.aberturalote.orderBy('ablnrocontrole').toArray();

      const formulario = sessionStorage.getItem('formulario', null);
      if (formulario) {
        this.formulario = JSON.parse(formulario);
        sessionStorage.removeItem('formulario');
      }
    },

    async $_submit() {
      if (this.$refs.form.validate()) {
      this.formulario.appdatapesagem = new Date();
      this.formulario.appdatapesagem.setHours(this.formulario.appdatapesagem.getHours() - 3);
        const a = await this.$refs.config.$_submit();
        if (a) {
          errorHandler('Enviado com sucesso!');
          sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
          Object.keys(this.formulario).forEach(k => this.formulario[k] = null);
          this.formulario.appdatapesagem = new Date();
          this.formulario.appdatapesagem.setHours(this.formulario.appdatapesagem.getHours() - 3);
          this.$refs.form.resetValidation()
        }
      } else {
        errorHandler('Formulario inválido, verifique os dados e envie novamente');
      }
    },

    $_somaPeso(nro) {
      const itensComEsseNro = this.lotes.filter((item) => {
        return item.applote === nro;
      })

      const soma = itensComEsseNro.reduce((acc, cur) => {
        return acc +  Number(cur.appquantidade);
      }, 0);
      return soma;
    },

    FormataData(date, formato) {
      // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
      return moment.tz(date, 'America/Sao_Paulo').local().format(formato);
    },

    proximoInput() {
      this.$refs.loteb.$children[0].focus();
    },

    $_openDetalhe(nro) {
      sessionStorage.setItem(OPLOCAL_STORAGE.nro, nro);
      this.$router.replace({ 
        ...this.$route,
        name: ROUTE_LOTES_FINALIZADOS.name,
      });
    },

    $_onClickQR() {
      sessionStorage.setItem('formulario', JSON.stringify(this.formulario));
      setTimeout(() => {
        this.$router.push({
          ...this.$route,
          name: ROUTE_QR.name,
        });
      });
    },

    async $_verificaQR() {
      const qr = await getSessionStorage(OPLOCAL_STORAGE.qr, '');
      if (qr && qr.length && qr !== 'null') {
        const lote  = (await db.aberturalote.where({ ablnrocontrole: qr }).toArray());
        if (!lote.length) {
          errorHandler('Lote não encontrado');
          return;
        }
        this.formulario.appidaberturalote = lote[0].ablidaberturalote;
        this.$refs.umidade.focus();
        if (typeof this.proximolote === 'object') {
          // this.$_adicionaLote(this.proximolote);
        } else {
          errorHandler('Erro ao adicionar novo lote')
        }
      }
      // sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
      // this.proximolote = JSON.parse(sessionStorage.getItem('lote'));
      // this.proximoembalagem= JSON.parse(sessionStorage.getItem('loteembalagem'));
      // this.w_peso = sessionStorage.getItem('peso', this.w_peso);
    }
  },

  created() {
    this.$_setup();
    this.$_verificaQR();
    SetupWebSocket();
  },

  watch: {
    w_peso() {
      let novoValor = this.w_peso;
        if (isNaN(novoValor))
            this.w_peso = novoValor;
        novoValor = novoValor.replace('.','');
        if (novoValor.length==1) {
            novoValor = '0.00' + novoValor;
        } else if (novoValor.length==3) {
            novoValor = '0.' + novoValor;
        } else {
            novoValor =  + novoValor.slice(0,-3) +'.' + novoValor.substr(-3);
        }
      this.w_peso = novoValor;
      sessionStorage.setItem('peso', this.w_peso);
    },

    proximolote() {
      sessionStorage.setItem('lote', JSON.stringify(this.proximolote));
    },

    proximoembalagem() {
      sessionStorage.setItem('loteembalagem', JSON.stringify(this.proximoembalagem));
    }
  }

  // watch: {
  //   proximolote(valor) {
  //     this.fabricalocal = (db.aberturalote.where({ fabidfabrica: valor.ablidfabrica  }).toArray())[0];
  //   }
  // }
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .space {
    margin-bottom: 45px;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
  }

  .card-secador {
    width: 90%;
    text-align: center;
    margin: 20px;
  }

  .linhas {
    height: 21%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .finalizado {
    background-color: #4CAF50;
  }

  .iniciado {
    background-color: #6b93ff;
  }

  .disponivel {
    background-color: #b9b9b9;
  }

  .digitavel {
    margin-bottom: 25px;
  }

    .linha-menor {
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
    font-size: 13px;
  }

  .inner-card {
    display: flex;
    justify-content: space-between;
    height: 67%;
  }

  .container-inner-items {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container-inner-items-fill {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card-btn {
    transform: translateY(10px);
  }

  .txt-center {
    text-align: center;
  }

  .input-container {
      width: 60%;
  }

  .container {
    width: 93vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contaainer {
    width: 93vw;
    max-width: 250px;
    flex-wrap: wrap;
  }

  .textfield {
    width: 90%;
  }

  .n-center >>> input {
      text-align: center;
  }

  .n-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .nn-center >>> input {
      text-align: center;
  }

  .nn-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  
</style>
