<template>
  <div>
    <div class="container">
        <v-form ref="form" lazy-validation>
          <form-config
            ref="config"
            :formulario="formulario"
            tabela="apontamentoproducao"
          />
            <div class="d-flex justify-center contaainer">
              <v-text-field ref="balanca"
              outlined label="Numero Balança" class="textfield n-center"
              background-color="white" type="number"
              :rules="rules.appnumerobalanca"
              v-model="formulario.appnumerobalanca"
              @keydown="$_limitaBalanca"
              @keyup.enter="$refs.balanca.blur(); $refs.lote.$children[0].focus();"></v-text-field>
              <div class="linha-qr">
                <card-auto-complete ref="lote"
                  label="Lote P.A."
                  class="textfield n-center"
                  :dados="lotesdaop" 
                  v-model="formulario.appidaberturalote"
                  itemLabel="ablnrocontrole"  
                  item-value="ablidaberturalote"
                  :return-object="false"
                  :rules="rules.ablidaberturalote"
                  hide-details
                  icon="mdi-qrcode-scan"
                  :action="$_onClickQR"
                  @keyup.enter="$refs.lote.$children[0].blur(); $refs.peso.focus()"
                />
                  <v-text-field 
                  outlined 
                  background-color="white"
                  type="numeric"
                  label="Bag"
                  class="numero-armazenamento n-center"
                  v-model="formulario.appnumerobag"
                  hide-details>
                  </v-text-field>
              </div>
              <!-- <v-text-field outlined label="Data/Hora" class="textfield n-center" readonly
              v-model="formulario.appdatapesagem"></v-text-field> -->
            <v-card class="descricaoextra" v-if="lotecompleto?.abldescricao">
              <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.abldescricao}}</b>
              <v-divider></v-divider>
              <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.ablgranulometria}}</b>
              <v-divider></v-divider>
              <b class="centerextra" style="font-weight: 600;">{{variedadeCompleta?.vardescricao}}</b>
              <v-divider v-if="apontamentoCompleto?.apppeso"></v-divider>
              <b class="centerextra" style="font-weight: 600;">{{apontamentoCompleto?.apppeso}}</b>
            </v-card>
            <v-text-field ref="peso"
            outlined label="Peso" class="textfield n-center" type="number"
            background-color="white" :rules="rules.apppeso"
            v-model="formulario.apppeso"
            @keyup.enter="$refs.peso.blur(); $refs.local.focus()"></v-text-field>
            <v-autocomplete ref="local"
            :items="locaisdaop"
            item-text="loadescricao"
            item-value="loaidlocalarmazenamento"
            outlined label="Local" 
            class="textfield n-center"
            background-color="white" 
            :rules="rules.appidlocalmovimentacaoestoque"
            v-model="formulario.appidlocalmovimentacaoestoque"
            @keyup.enter="$refs.local.blur(); $_submit"></v-autocomplete>
            <v-autocomplete ref="local"
            :items="turnosdaop"
            item-text="tupidturno"
            item-value="tupidturno"
            outlined label="Turno" 
            class="textfield n-center"
            background-color="white" 
            :rules="rules.appidturno"
            v-model="formulario.appidturno"
            @keyup.enter="$refs.local.blur(); $_submit"></v-autocomplete>
            <v-dialog v-model="dialogloterepetido" persistent v-if="existeapontamento">
              <v-card>
                <v-card-title class="text-h6 primary white--text">
                  Lançamento Repetido
                </v-card-title>
                <br/>
                <v-card-text>
                  Já existe um apontamento para o lote selecionado. Escreva uma observação.
                </v-card-text>
                <v-text-field 
                v-model="formulario.appobsduplicado"
                outlined 
                dense 
                label="Observação" 
                :rules="rules.appobsduplicado"
                class="mx-5">
                </v-text-field>
                <v-divider>
                </v-divider>
                <v-card-actions class="d-flex justify-space-between">
                  <v-btn @click="dialogloterepetido = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="$_submit">
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn color="primary" class="salvar" @click="$_verificaDuplicado" width="125px">
              <div v-if="!enviando">Salvar</div>
              <v-progress-circular v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-btn>
            </div>
              <!-- <v-btn @click="$_cancelar">Cancelar</v-btn> -->
        </v-form>
      <!-- <v-text-field></v-text-field> -->
      <br/>
    </div>
  </div>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import { ROUTE_PRODUCAO_CADASTRO, ROUTE_LOTES_FINALIZADOS, ROUTE_QR, ROUTE_PRODUCAO_C  } from '@/constants/ROUTES';

import FormConfig from '@/components/form/components/form-config/Index.vue';
import CardAutoComplete from '@/components/core/card-auto-complete/Index.vue'

import { OPLOCAL_STORAGE } from '@/constants/STORAGE'

import geraData from '@/utils/geradata'


// import { OPLOCAL_STORAGE, CADPRODUCAO_STORAGE } from '@/constants/STORAGE';

import db from '@/plugins/dexie';

import moment from 'moment-timezone';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}


// import AppCardAtividadeSkeleton from '../../components/app/card/atividade/Skeleton.vue';
import { SetupWebSocket } from '../../utils/ws/websocket';
import errorHandler from '@/utils/error-handler';

export default {
  name: 'ViewLoteC',

  components: {
    FormConfig,
    CardAutoComplete,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_PRODUCAO_CADASTRO,
      ],

      fabrica: null,
      loading: true,
      lotes: null,

      lotesdaop: null,
      locaisdaop: null,
      turnosdaop: null,
  
      lotesfechados: null,
      proximolote: null,
      proximoembalagem: null,
      idordemproducao: null,

      formulario: {
        appnumerobalanca: 1,
        apppeso: null,
        appidaberturalote: null,
        appidlocalmovimentacaoestoque: null,
        appnumerobag: null,
        appdatapesagem: null,
        appdataalteracao: null,
        appidturno: null,
      },
      lotecompleto: null,
      apontamentoCompleto: null,
      variedadeCompleta: null,

      rules: {
        appnumerobalanca: [ v => !!v || 'Numero Balança é obrigatório' ],
        apppeso: [ v => !!v || 'Peso é obrigatório' ],
        appidlocalmovimentacaoestoque: [ v => !!v || 'Local é obrigatório' ],
        ablidaberturalote: [ v => !!v  || 'Lote é obrigatório'],
        appobsduplicado: [ v => !!v || 'Observação é obrigatória'],
        appidturno: [ v => !!v || 'Turno é obrigatório'],
      },

      // ESSA VIEW ESTÁ COM VARIAS FUNÇÕES A MAIS, RETIRAR DEPOIS
      

      itensComEsseNro: null,

      lotesMaisRecentes: [],

      dialogloterepetido: false,

      existeapontamento: false,

      enviando: false,
    };
  },

  methods: {

    async $_setup() {
      this.formulario.appdatapesagem = new Date();
      this.formulario.appdatapesagem.setHours(this.formulario.appdatapesagem.getHours() - 3);
      this.lotesdaop = await db.aberturalote.orderBy('ablnrocontrole').toArray();
      this.locaisdaop = await db.localarmazenamento.where({ loausadoapppesagem: 'S' }).toArray();
      this.turnosdaop = await db.turno.toArray();
      this.turnosdaop.forEach((turno) => {
        const abertura = new Date(turno.tupdataabertura);
        const fechamento = new Date(turno.tupdatafechamento);

        if (
          (new Date().toLocaleTimeString() >= abertura.toLocaleTimeString()) 
          && 
          (new Date().toLocaleTimeString() <= fechamento.toLocaleTimeString())
          ) {
          this.formulario.appidturno = turno.tupidturno;
        }
      })

      const formulario = sessionStorage.getItem('formulario', null);
      if (formulario) {
        this.formulario = JSON.parse(formulario);
        this.$_verificaQR();
        sessionStorage.removeItem('formulario');
      }

      this.$refs.lote.$children[0].focus();
    },

    async $_limitaBalanca(e) {
      if (this.formulario.appnumerobalanca?.length > 2 && e.key !== 'Enter') {
        setTimeout(() => {
          this.formulario.appnumerobalanca= this.formulario.appnumerobalanca.slice(0, -1) //'abcde'
        })
      }
    },

    async $_submit() {
      if (this.enviando) {
        return;
      }
      if (this.$refs.form.validate()) {
        try {
          this.enviando = true;
          this.formulario.appdatapesagem = geraData(),
          this.formulario.appdataalteracao = geraData();
          // this.formulario.appdatapesagem = new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }),
          // this.formulario.appdataalteracao = new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
          const res = await this.$refs.config.$_submit();
          if (res) {
            errorHandler('Enviado com sucesso!');
            this.dialogloterepetido = false;
            sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
            this.formulario = {
              appnumerobalanca: 1,
              apppeso: null,
              appidaberturalote: null,
              appidlocalmovimentacaoestoque: null,
              appnumerobag: null,
              appdatapesagem: geraData(),
              appdataalteracao: geraData(),
              appidturno: this.formulario.appidturno,           
            }
            this.lotecompleto = null;
            this.$refs.form.resetValidation();
            this.enviando = false;
          }
        } catch(e) {
          this.enviando = false;
          console.log(e, 'eeeee');
          if(e.code == 'ERR_NETWORK'){
            errorHandler('Erro de conexão');
          } else {
            errorHandler(e);
          }
        }
      } else {
        errorHandler('Formulario inválido, verifique os dados e envie novamente');
      }
    },

    $_somaPeso(nro) {
      const itensComEsseNro = this.lotes.filter((item) => {
        return item.applote === nro;
      })

      const soma = itensComEsseNro.reduce((acc, cur) => {
        return acc +  Number(cur.appquantidade);
      }, 0);
      return soma;
    },

    $_verificaDuplicado() {
      if (this.existeapontamento) {
        this.dialogloterepetido = true;
      } else {
        this.$_submit();
      }
    },

    FormataData(date, formato) {
      // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
      return moment.tz(date, 'America/Sao_Paulo').local().format(formato);
    },

    proximoInput() {
      this.$refs.loteb.$children[0].focus();
    },

    $_openDetalhe(nro) {
      sessionStorage.setItem(OPLOCAL_STORAGE.nro, nro);
      this.$router.replace({ 
        ...this.$route,
        name: ROUTE_LOTES_FINALIZADOS.name,
      });
    },

    $_onClickQR() {
      sessionStorage.setItem('formulario', JSON.stringify(this.formulario));
      sessionStorage.setItem('rota-anterior', ROUTE_PRODUCAO_C.path);
      setTimeout(() => {
        this.$router.push({
          ...this.$route,
          name: ROUTE_QR.name,
        });
      });
    },

    async $_verificaQR() {
      const qr = await getSessionStorage(OPLOCAL_STORAGE.qr, '');
      const numerobag = await getSessionStorage(OPLOCAL_STORAGE.numerobag, '');
      if (numerobag) {
        this.formulario.appnumerobag = numerobag;
      }
      if (qr && qr.length && qr !== 'null') {
        const lote  = (await db.aberturalote.where({ ablnrocontrole: qr }).toArray());
        if (!lote.length) {
          errorHandler('Lote não encontrado');
          return;
        }
        this.lotecompleto = null;
        this.lotecompleto = lote[0];
        this.formulario.appidaberturalote = lote[0].ablidaberturalote;
        this.$refs.peso.focus();
        if (typeof this.proximolote === 'object') {
          // this.$_adicionaLote(this.proximolote);
        } else {
          errorHandler('Erro ao adicionar novo lote')
        }
      }
      // sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
      // this.proximolote = JSON.parse(sessionStorage.getItem('lote'));
      // this.proximoembalagem= JSON.parse(sessionStorage.getItem('loteembalagem'));
    }
  },

  created() {
    this.$_setup();
    SetupWebSocket();
  },

  computed: {
    $$appidaberturalote() {
      return this.formulario.appidaberturalote;
    },

    $$numerobag() {
      return this.formulario.appnumerobag;
    }
  },

  watch: {
    $$appidaberturalote: {
      handler() {
        setTimeout(() => {
          if (this.formulario.appidaberturalote) {
            this.lotecompleto = null;
            const loteCompleto = (db.aberturalote.where({ ablidaberturalote: this.formulario.appidaberturalote }).toArray());
            loteCompleto.then((item) => {
              this.lotecompleto = item[0];
              db.produto.where({ proidproduto: item[0].ablidproduto }).toArray().then((itemProduto) => {
                if (itemProduto[0]?.proidvariedade) {
                  db.variedade.where({ varidvariedade: itemProduto[0]?.proidvariedade }).toArray().then((itemVariedade) => {
                    this.variedadeCompleta = itemVariedade[0];
                  })
                }
              })
            })
            const apontamentoExiste = (db.apontamentoproducao.where({ 
              appidaberturalote: this.formulario.appidaberturalote,
              appnumerobag: Number(this.formulario.appnumerobag)
            }).toArray());
            apontamentoExiste.then((item) => {
              this.existeapontamento = !!item.length
            })
          }
        }) 
      }
    },

    $$numerobag: {
      handler() {
        setTimeout(() => {
          if (this.formulario.appidaberturalote) {
            const apontamentoExiste = (db.apontamentoproducao.where({ 
              appidaberturalote: this.formulario.appidaberturalote,
              appnumerobag: Number(this.formulario.appnumerobag)
            }).toArray());
            apontamentoExiste.then((item) => {
              this.existeapontamento = !!item.length
            })
          }
        }) 
      }
    },

    proximolote() {
      sessionStorage.setItem('lote', JSON.stringify(this.proximolote));
    },

    proximoembalagem() {
      sessionStorage.setItem('loteembalagem', JSON.stringify(this.proximoembalagem));
    }
  }

  // watch: {
  //   proximolote(valor) {
  //     this.fabricalocal = (db.aberturalote.where({ fabidfabrica: valor.ablidfabrica  }).toArray())[0];
  //   }
  // }
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }


  .center {
    width: 100%;
    text-align: center;
  }

  .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
  }

  .card-secador {
    width: 90%;
    text-align: center;
    margin: 20px;
  }

  .linhas {
    height: 21%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .finalizado {
    background-color: #4CAF50;
  }

  .iniciado {
    background-color: #6b93ff;
  }

  .disponivel {
    background-color: #b9b9b9;
  }

  .digitavel {
    margin-bottom: 25px;
  }

    .linha-menor {
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
    font-size: 13px;
  }

  .inner-card {
    display: flex;
    justify-content: space-between;
    height: 67%;
  }

  .container-inner-items {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container-inner-items-fill {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card-btn {
    transform: translateY(10px);
  }

  .txt-center {
    text-align: center;
  }

  .input-container {
      width: 60%;
  }

  .container {
    width: 93vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contaainer {
    width: 93vw;
    max-width: 250px;
    flex-wrap: wrap;
  }

  .textfield {
    width: 90%;
  }

  .n-center >>> input {
      text-align: center;
  }

  .n-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .nn-center >>> input {
      text-align: center;
  }

  .nn-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .linha-qr {
    display: flex;
  }

  .numero-armazenamento {
    width: 33%;
  }

  .descricaoextra {
    color: #3d68de;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 5px;
    background-color: #fdffd2;
  }

  .centerextra {
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
  }


  
</style>
